import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import GenericCard from "components/UI/GenericCard/GenericCard";
import { Area, Line } from "@ant-design/plots";
import { Segmented } from "antd";
import { PercentageOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { toFixed } from "utils/helpers";

const AccProfitChart = (props) => {
  const { strategy } = props;
  const [type, setType] = useState("accProfit");
  console.log("strategy", strategy);

  const data = useMemo(() => {
    if (!strategy?.transactions) return [];
    return strategy.transactions.slice().reverse();
  }, [strategy]);

  const dataNormalized = useMemo(() => {
    if (!data.length) return [];

    const initialBalance = strategy.initialBalance;

    return data.reduce((acc, item) => {
      if (acc.length === 0) {
        acc.push({
          // closeDate: "2000-01-01",
          closeDate: dayjs(strategy.created).format("YYYY-MM-DD"),
          accProfit: 0,
          accProfitPercent: 0,
          buyHoldReturn: 0,
        });
      }

      const lastAcc = acc[acc.length - 1];
      const balance = lastAcc.accProfit + item.netProfit.value;
      const buyHold = (item.closeRate / strategy.firstClosePrice - 1) * 100;
      const accProfitPercent = toFixed((balance / initialBalance) * 100, 2);

      acc.push({
        closeDate: dayjs(item.closeDate).format("YYYY-MM-DD"),
        accProfit: toFixed(balance, 2),
        accProfitPercent,
        buyHoldReturn: toFixed(buyHold, 2),
      });

      return acc;
    }, []);
  }, [data, strategy]);

  const dataWithBuyHold = useMemo(() => {
    if (type !== "buyHold" || !dataNormalized.length) return [];
    return dataNormalized.reduce(
      (acc, item) => {
        acc.push(
          {
            date: dayjs(item.closeDate).format("YYYY-MM-DD"),
            value: item.accProfitPercent,
            type: "Profit %",
          },
          {
            date: dayjs(item.closeDate).format("YYYY-MM-DD"),
            value: item.buyHoldReturn,
            type: "Buy and Hold %",
          }
        );
        return acc;
      },
      [
        { date: "2000-01-01", value: 0, type: "Profit %" },
        { date: "2000-01-01", value: 0, type: "Buy and Hold %" },
      ]
    );
  }, [dataNormalized, type]);

  const config = {
    data: dataNormalized,
    padding: "auto",
    xField: "closeDate",
    yField: type,
    slider: {},
    meta: {
      accProfitPercent: {
        alias: "Profit %",
        formatter: (v) => `${v}%`,
      },
      accProfit: {
        alias: "Profit",
        formatter: (v) => `$${v}`,
      },
    },
  };

  const configLine = {
    data: dataWithBuyHold,
    xField: "date",
    yField: "value",
    limitInPlot: false,
    seriesField: "type",
    slider: {},
    meta: {
      value: {
        alias: "Value",
        formatter: (v) => `${v}%`,
      },
    },
  };

  return (
    <GenericCard
      title="Profit over time"
      maxHeight={1000}
      isCardOpenByDefault={false}
      className="table-card"
    >
      <div className="pb-4">
        <Segmented
          options={[
            {
              label: "USD",
              value: "accProfit",
            },
            {
              label: "Profit",
              icon: <PercentageOutlined />,
              value: "buyHold",
            },
          ]}
          value={type}
          onChange={setType}
        />
      </div>
      {type === "accProfit" ? (
        <Area {...config} height={600} autoFit={false} />
      ) : (
        <Line {...configLine} height={600} autoFit={false} />
      )}
    </GenericCard>
  );
};

AccProfitChart.propTypes = {
  strategy: PropTypes.shape({
    initialBalance: PropTypes.number.isRequired,
    firstClosePrice: PropTypes.number.isRequired,
    transactions: PropTypes.arrayOf(
      PropTypes.shape({
        closeDate: PropTypes.string.isRequired,
        netProfit: PropTypes.shape({
          value: PropTypes.number.isRequired,
        }).isRequired,
        closeRate: PropTypes.number.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default AccProfitChart;
