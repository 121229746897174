import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment/moment";
import { App, Button, List } from "antd";

import { ExclamationCircleFilled } from "@ant-design/icons";
import { DeleteOutlined } from "@ant-design/icons";
import ProfileAvatar from "components/UI/ProfileAvatar";

import { apiDeleteModel } from "api/apiAiModels";
import GenericCard from "components/UI/GenericCard/GenericCard";
import PredictButton from "./PredictButton";
import ProtectedContent from "components/UI/ProtectedContent";

const ChooseModel = (props) => {
  const { modelsList, getModelsList, handleCheckAiSymbol } = props;
  const { message, modal } = App.useApp();
  const [isSearchAllPending, setIsSearchAllPending] = useState(false);
  const [deleteModelPending, setDeleteModelPending] = useState(false);

  const deleteModelById = async (modelId) => {
    setDeleteModelPending(true);
    try {
      await apiDeleteModel(modelId);
      getModelsList();
      message.success("Model deleted successfully");
    } catch (err) {
      message.error(err.response.data.message);
    }
    setDeleteModelPending(false);
  };

  const showDeleteConfirm = (modelId) => {
    modal.confirm({
      title: "Are you sure you want to delete this model?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteModelById(modelId);
      },
      onCancel() {},
    });
  };

  const onSearchAllSuccess = () => {
    setIsSearchAllPending(false);
    message.success("Search all finished successfully");
  };

  const onSearchAllFailure = (errorMessage = "Search all failed") => {
    setIsSearchAllPending(false);
    message.error(errorMessage);
  };

  const handleSearchAllModels = async () => {
    setIsSearchAllPending(true);

    try {
      await handleCheckAiSymbol();
      onSearchAllSuccess();
    } catch (err) {
      onSearchAllFailure(err?.response?.data?.message);
    }
  };

  return (
    <GenericCard title="Choose models">
      <div>
        <ProtectedContent requiredPermissions={["manager"]}>
          <div className="results-table-buttons">
            <Button
              disabled={isSearchAllPending}
              onClick={handleSearchAllModels}
            >
              Search all models
            </Button>
          </div>
        </ProtectedContent>

        <List
          size="small"
          bordered
          dataSource={modelsList}
          renderItem={(item) => (
            <List.Item
              actions={[
                item.usedByStrategies === 0 && (
                  <ProtectedContent requiredPermissions={["manager"]}>
                    <Button
                      icon={<DeleteOutlined />}
                      onClick={() => showDeleteConfirm(item._id)}
                      danger
                      ghost
                      loading={deleteModelPending}
                    />
                  </ProtectedContent>
                ),
                <PredictButton
                  model={item}
                  handleCheckAiSymbol={handleCheckAiSymbol}
                  isDisabled={isSearchAllPending}
                />,
              ]}
            >
              <List.Item.Meta
                avatar={<ProfileAvatar user={item?.creator} />}
                title={item.name}
                description={
                  <>
                    <p>{item.leads.map((item) => `${item}, `)}</p>
                    <div className="flex">
                      <span className="pe-2">Trained data:</span>
                      <div className="flex gap-2" style={{ color: "#ffffff" }}>
                        <div>
                          {moment(item.results?.start_date).format("DD/MM/YY")}
                        </div>
                        {" - "}
                        <div>
                          {moment(item.results?.end_date).format("DD/MM/YY")}
                        </div>
                      </div>
                    </div>
                    <p>
                      Profit target:{" "}
                      <span style={{ color: "#ffffff" }}>
                        {((item.results?.perc_to_gain - 1) * 100).toFixed()}% in{" "}
                        {item.results?.days} days
                      </span>
                    </p>

                    <p>
                      Buy/total:{" "}
                      <span style={{ color: "#ffffff" }} className="pe-4">
                        {(item.results?.buy_from_total * 100).toFixed(1)}%
                      </span>
                      <span>Accuarcy: </span>
                      <span style={{ color: "#ffffff" }}>
                        {(item.results?.accuracy * 100).toFixed(1)}%
                      </span>
                    </p>

                    <p>
                      Indicators:{" "}
                      {item.results?.indicators &&
                        Object.entries(item.results.indicators).map(
                          ([indicator, value]) => {
                            if (indicator === "MACD") {
                              return (
                                <span
                                  style={{ color: "#ffffff" }}
                                  className="pe-4 inline-flex"
                                >
                                  {indicator}:{" "}
                                  {Object.values(value).map(
                                    (value) => `${value} `
                                  )}
                                </span>
                              );
                            }
                            return (
                              <span
                                style={{ color: "#ffffff" }}
                                className="pe-4 inline-flex"
                              >
                                {indicator}: {value}
                              </span>
                            );
                          }
                        )}
                    </p>

                    <p>
                      {item?.creator
                        ? `Created by ${item.creator.firstName} ${item?.creator?.lastName}, `
                        : ""}
                      {moment(item.date).format("HH:mm DD/MM/YY")}
                      {item.usedByStrategies > 0 && (
                        <span style={{ color: "#ffffff" }} className="ps-4">
                          Used by {item.usedByStrategies} strategies
                        </span>
                      )}
                    </p>
                  </>
                }
              />
            </List.Item>
          )}
        />
      </div>
    </GenericCard>
  );
};

ChooseModel.propTypes = {
  setChoosenModel: PropTypes.func,
  choosenModel: PropTypes.object,
};

export default ChooseModel;
