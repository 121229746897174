import { v4 as uuidv4 } from "uuid";
import {
  addNumberToAverage,
  calcProfitPerc,
  getPercentile,
  toFixed,
} from "utils/helpers";
import dayjs from "dayjs";

const returnPremiumValue = (strategy, shares) => {
  if (shares * strategy.premiumPerShare > strategy.premiumValue) {
    return shares * strategy.premiumPerShare;
  }
  return strategy.premiumValue;
};

const updateRunUpDrawdown = (type, order, rate, date) => {
  order[type].rate = rate;
  order[type].value = toFixed(
    rate * order.amount - order.openRate * order.amount
  );
  order[type].perc = calcProfitPerc(order.openRate, rate);
  order[type].date = date;
};

const executeBuyOrder = (strategy, candle) => {
  const currentRate = candle.close + strategy.params.slippage;
  let buyAmount = Math.floor(
    strategy.params.orderSizeType === "percent"
      ? (strategy.equity * (strategy.params.orderSize / 100)) / currentRate
      : strategy.params.orderSize / currentRate
  );

  // margin call
  if (buyAmount < 1) {
    // buyAmount = 1;
    return;
  }

  const isInValley = strategy.equity < strategy.metrics.maxEquity;

  const openValue = toFixed(buyAmount * currentRate);

  const order = {
    symbol: strategy.symbol,
    amount: buyAmount,
    orderStatus: "active",
    openRate: toFixed(currentRate),
    openValue: toFixed(openValue),
    netOpenValue: toFixed(openValue + returnPremiumValue(strategy, buyAmount)),
    netOpenRate: toFixed(
      (openValue + returnPremiumValue(strategy, buyAmount)) / buyAmount
    ),
    currentRate: toFixed(currentRate),
    advicedBuyPrice: toFixed(candle.close),
    openDate: candle.date,
    dealDaysOpen: 0,
    premiumFee: returnPremiumValue(strategy, buyAmount),
    source: "algo",
    runup: {},
    drawdown: {},
    min: {},
    max: {},
    profit: {
      value: 0,
      perc: 0,
    },
    buyPredictionsInRow: 0,
    noBuyPredictionsInRow: 0,
  };

  if (isInValley) order.valley = true;

  updateRunUpDrawdown("runup", order, currentRate, candle.date);
  updateRunUpDrawdown("drawdown", order, currentRate, candle.date);
  updateRunUpDrawdown("min", order, currentRate, candle.date);
  updateRunUpDrawdown("max", order, currentRate, candle.date);

  strategy.openOrders.push(order);
  strategy.metrics.totalCommissions += returnPremiumValue(strategy, buyAmount);
  strategy.balance -= order.openValue;
};

const executeSellOrder = (order, strategy, candle, cause, closeSignal) => {
  const currentRate = toFixed(candle.close - strategy.params.slippage);
  order.advicedSellPrice = toFixed(candle.close);

  order.orderStatus = "closed";
  order.premiumFee += returnPremiumValue(strategy, order.amount);

  order.closeRate = toFixed(currentRate);
  order.closeValue = toFixed(order.amount * currentRate);
  order.netCloseValue = toFixed(
    order.closeValue - returnPremiumValue(strategy, order.amount)
  );
  order.netCloseRate = toFixed(order.netCloseValue / order.amount);

  order.closeDate = candle.date;
  order.closeSignal = closeSignal; // before confirm
  order.closeReason = cause; // before confirm

  order.durationMs = +new Date(candle.date) - +new Date(order.openDate);
  order.profit = {
    value: toFixed(order.closeValue - order.openValue),
    perc: calcProfitPerc(order.openValue, order.closeValue),
  };
  order.netProfit = {
    value: order.profit.value - order.premiumFee,
    perc: calcProfitPerc(order.netOpenValue, order.netCloseValue),
  };
  order.profit.isClosedWithProfit = order.profit.value > 0;
  order.netProfit.isClosedWithProfit = order.netProfit.value > 0;

  if (strategy.metrics.highestProfit < order.profit.perc) {
    strategy.metrics.highestProfit = order.profit.perc;
  }
  if (strategy.metrics.highestLoss > order.profit.perc) {
    strategy.metrics.highestLoss = order.profit.perc;
  }

  strategy.metrics.totalTransactions++;

  strategy.metrics.totalCommissions += returnPremiumValue(
    strategy,
    order.amount
  );
  strategy.metrics.averageProfit = addNumberToAverage(
    strategy.metrics.averageProfit,
    strategy.metrics.totalTransactions,
    order.profit.perc
  );
  strategy.metrics.averageDuration = addNumberToAverage(
    strategy.metrics.averageDuration,
    strategy.metrics.totalTransactions,
    order.dealDaysOpen,
    2
  );

  strategy.metrics.closedWithProfit += order.profit.isClosedWithProfit;
  strategy.metrics.closedWithNetProfit += order.netProfit.isClosedWithProfit;
  strategy.metrics.successRate = toFixed(
    (strategy.metrics.closedWithProfit / strategy.metrics.totalTransactions) *
      100
  );

  if (order.profit.value > 0) {
    strategy.metrics.grossProfit += order.profit.value;
  } else {
    strategy.metrics.grossLoss -= order.profit.value;
  }

  strategy.metrics.profitFactor = toFixed(
    strategy.metrics.grossProfit / strategy.metrics.grossLoss,
    3
  );

  // ** NEW METRICS **
  order.isReachedModelTarget =
    order.profit.perc >= strategy.params.takeProfitPercent;
  strategy.metrics.reachedModelTarget += order.isReachedModelTarget;
  strategy.metrics.strategySuccessRate = toFixed(
    (strategy.metrics.reachedModelTarget / strategy.metrics.totalTransactions) *
      100
  );

  if (order.profit.isClosedWithProfit) {
    strategy.metrics.avgProfit = addNumberToAverage(
      strategy.metrics.avgProfit,
      strategy.metrics.winningTrades,
      order.profit.perc
    );
    strategy.metrics.winningTrades++;
    strategy.metrics.averageWinDuration = addNumberToAverage(
      strategy.metrics.averageWinDuration,
      strategy.metrics.winningTrades,
      order.dealDaysOpen,
      2
    );
  } else {
    strategy.metrics.avgLoss = addNumberToAverage(
      strategy.metrics.avgLoss,
      strategy.metrics.lossingTrades,
      order.profit.perc
    );
    strategy.metrics.lossingTrades++;
    strategy.metrics.averageLossDuration = addNumberToAverage(
      strategy.metrics.averageLossDuration,
      strategy.metrics.lossingTrades,
      order.dealDaysOpen,
      2
    );
  }

  // ** NEW METRICS END **

  strategy.balance += order.closeValue - order.premiumFee;
  strategy.equity += order.netProfit.value;
  strategy.metrics.pnl = toFixed(strategy.metrics.pnl + order.netProfit.value);

  order.balance = toFixed(strategy.balance, 0);
  order.accProfit = toFixed(strategy.equity - strategy.initialBalance, 0);
  order.accProfitPercent = calcProfitPerc(
    strategy.initialBalance,
    strategy.equity
  );
  order.buyHoldReturn = strategy.metrics.buyHoldReturn;

  order.strategySuccessRate = strategy.metrics.strategySuccessRate;

  strategy.transactions.unshift(order);
  strategy.openOrders.splice(strategy.openOrders.indexOf(order), 1);

  strategy.metrics.pnlPercent = calcProfitPerc(
    strategy.initialBalance,
    strategy.equity
  );
  if (strategy.params.resetTrendAfterSell) {
    strategy.buyPredictionsInRow = 0;
    strategy.noBuyPredictionsInRow = 0;
  }

  if (strategy.equity > strategy.metrics.maxEquity) {
    strategy.metrics.maxEquity = strategy.equity;
  }

  const drawdown = calcProfitPerc(strategy.metrics.maxEquity, strategy.equity);
  if (drawdown < strategy.metrics.maxDrawdown) {
    strategy.metrics.maxDrawdown = drawdown;
  }
};

// // if numDaysForbiddenAfter = 0, then only the same date is forbidden
// const checkIfDateAllowed = (
//   currentDate,
//   forbiddenDate,
//   numDaysForbiddenAfter = 0
// ) => {
//   forbiddenDate = new Date(forbiddenDate);
//   currentDate = new Date(currentDate);

//   // Calculate the date N days later
//   const date14DaysLater = new Date(forbiddenDate);
//   date14DaysLater.setDate(forbiddenDate.getDate() + numDaysForbiddenAfter);
//   // Check if the current date is on or after the input date and before or on the date N days later
//   if (currentDate >= forbiddenDate && currentDate <= date14DaysLater) {
//     return false;
//   }
//   return true;
// };

const checkIfCanEnterPosition = (strategy, currentPredict) => {
  // const notAllowedEarningDate = "2023-04-13";
  // const isDayAllowed = checkIfDateAllowed(
  //   currentPredict.date,
  //   notAllowedEarningDate,
  //   0
  // );
  const isDayAllowed = true;
  // let isDayAllowed = true;
  // const allowedMonths = [
  //   "01",
  //   "02",
  //   // "03",
  //   "04",
  //   "05",
  //   "07",
  //   "08",
  //   "11",
  //   //
  //   // "06",
  //   // "09",
  //   // "10",
  //   // "12",
  // ];
  // const checkIfMonthAllowed = (currentPredict) => {
  //   const currentMonth = dayjs(currentPredict.date).format("MM");
  //   return allowedMonths.includes(currentMonth);
  // };

  // if (!checkIfMonthAllowed(currentPredict)) {
  //   isDayAllowed = false;
  // }

  return (
    strategy.openOrders.length < strategy.params.maxOpenOrders &&
    strategy.buyPredictionsInRow >= strategy.params.enterPositionBuyCount &&
    isDayAllowed
  );
};

const checkShouldExitPosition = (order, strategy, currentPredict) => {
  let cause, closeSignal;
  const percChange = calcProfitPerc(order.openRate, currentPredict.close);

  if (
    strategy.params.takeProfitPercent !== 0 &&
    percChange > +strategy.params.takeProfitPercent
  ) {
    // close % profit
    cause = `Profit over ${strategy.params.takeProfitPercent}%`;
    closeSignal = "profit";
  } else if (
    strategy.params.stopLossPercent !== 0 &&
    percChange < +strategy.params.stopLossPercent
  ) {
    // close % loss
    cause = `Loss over ${strategy.params.stopLossPercent}%`;
    closeSignal = "loss";
    // } else if (
    //   strategy.params.trailingStopLossPercent !== 0 &&
    //   order.drawdown.perc < +strategy.params.trailingStopLossPercent
    // ) {
    //   // close % trailing loss
    //   cause = `Trailing loss over ${strategy.params.trailingStopLossPercent}%`;
    //   closeSignal = "tralingloss";
  } else if (
    strategy.params.exitPositionNoBuyCount !== 0 &&
    order.noBuyPredictionsInRow >= +strategy.params.exitPositionNoBuyCount
  ) {
    // close after N no buy
    cause = `${strategy.params.exitPositionNoBuyCount} no buys`;
    closeSignal = "nobuy";
  } else if (
    order.dealDaysOpen !== 0 &&
    order.dealDaysOpen >= +strategy.params.daysOpenExitCount
  ) {
    // close after N days
    cause = `${+strategy.params.daysOpenExitCount} days exit`;
    closeSignal = "daysexit";
  } else {
    return;
  }

  executeSellOrder(order, strategy, currentPredict, cause, closeSignal);
};

const saveRunupDrawdown = (strategy, order, currentPredict) => {
  // save max value
  if (currentPredict.close > order.runup.rate) {
    updateRunUpDrawdown(
      "runup",
      order,
      currentPredict.close,
      currentPredict.date
    );
  }

  // save min value
  if (currentPredict.close < order.drawdown.rate) {
    updateRunUpDrawdown(
      "drawdown",
      order,
      currentPredict.close,
      currentPredict.date
    );
  }
};

// low and high during day values
const saveMinMaxValues = (order, currentPredict) => {
  // save max value
  if (currentPredict.high > order.max.rate) {
    updateRunUpDrawdown("max", order, currentPredict.high, currentPredict.date);
  }

  // save min value
  if (currentPredict.low < order.min.rate) {
    updateRunUpDrawdown("min", order, currentPredict.low, currentPredict.date);
  }
};

const updateContiniousTrend = (strategy, currentPredict) => {
  if (currentPredict === "buy") {
    strategy.buyPredictionsInRow += 1;
    strategy.noBuyPredictionsInRow = 0;
  } else if (currentPredict === "no buy") {
    strategy.noBuyPredictionsInRow += 1;
    strategy.buyPredictionsInRow = 0;
  }
  strategy.openOrders.forEach((order) => {
    if (currentPredict === "buy") {
      order.buyPredictionsInRow += 1;
      order.noBuyPredictionsInRow = 0;
    } else if (currentPredict === "no buy") {
      order.noBuyPredictionsInRow += 1;
      order.buyPredictionsInRow = 0;
    }
  });
};

const updateBuyHoldReturnPercent = (strategy, currentPredict) => {
  if (!strategy.firstClosePrice) {
    strategy.firstClosePrice = currentPredict.close;
  }
  strategy.metrics.buyHoldReturn = calcProfitPerc(
    strategy.firstClosePrice,
    currentPredict.close
  );
};

const updateStrategyDataDaily = (strategy, currentPredict) => {
  // currentPredict - new daily predict
  updateContiniousTrend(strategy, currentPredict.prediction);
  updateBuyHoldReturnPercent(strategy, currentPredict);
  strategy.lastUpdate = currentPredict.date;

  if (strategy.openOrders.length > 0) {
    strategy.openOrders.forEach((order) => {
      saveRunupDrawdown(strategy, order, currentPredict);
      saveMinMaxValues(order, currentPredict);
      order.dealDaysOpen++;
      order.profit = {
        value: toFixed(currentPredict.close * order.amount - order.openValue),
        perc: calcProfitPerc(
          order.openValue,
          currentPredict.close * order.amount
        ),
      };
      order.currentRate = toFixed(currentPredict.close);
    });
  }
};

const calculateStandardDeviation = (transactions, strategy) => {
  if (transactions.length === 0) {
    return 0;
  }

  const avgProfit = strategy.metrics.averageProfit;

  // Calculate the standard deviation of returns
  const squaredDifferences = transactions.map((transaction) =>
    Math.pow(transaction.profit.perc - avgProfit, 2)
  );
  const variance =
    squaredDifferences.reduce(
      (sum, squaredDifference) => sum + squaredDifference,
      0
    ) / transactions.length;
  const standardDeviation = Math.sqrt(variance);
  return toFixed(standardDeviation, 2);
};

const calculateUpsideDeviation = (transactions, strategy) => {
  if (transactions.length === 0) {
    return 0;
  }
  const profitTransactions = transactions.filter(
    (transaction) => transaction.profit.perc > 0
  );
  if (transactions.length === 0 || profitTransactions.length === 0) {
    return 0;
  }
  const avgProfit = strategy.metrics.avgProfit;
  // Calculate the standard deviation of returns
  const squaredDifferences = profitTransactions.map((transaction) =>
    Math.pow(transaction.profit.perc - avgProfit, 2)
  );
  const variance =
    squaredDifferences.reduce(
      (sum, squaredDifference) => sum + squaredDifference,
      0
    ) / profitTransactions.length;
  const upsideDeviation = Math.sqrt(variance);
  return toFixed(upsideDeviation, 2);
};

const calculateDownsideDeviation = (transactions, strategy) => {
  const negativeTransactions = transactions.filter(
    (transaction) => transaction.profit.perc < 0
  );
  if (transactions.length === 0 || negativeTransactions.length === 0) {
    return 0;
  }
  const avgLoss = strategy.metrics.avgLoss;
  // Calculate the standard deviation of returns
  const squaredDifferences = negativeTransactions.map((transaction) =>
    Math.pow(transaction.profit.perc - avgLoss, 2)
  );
  const variance =
    squaredDifferences.reduce(
      (sum, squaredDifference) => sum + squaredDifference,
      0
    ) / negativeTransactions.length;
  const downsideDeviation = Math.sqrt(variance);
  return toFixed(downsideDeviation, 2);
};

const calculateSharpeOrSortinoRatio = (strategy, deviationTradeReturn) => {
  if (strategy.transactions.length === 0) {
    return 0;
  }

  const annualRiskFreeRate = 0.03;
  const avgTradesPerYear = strategy.metrics.avgTransactionsPerYear;

  const averageTradeReturn = strategy.metrics.averageProfit / 100;
  const riskFreeRatePerTrade = annualRiskFreeRate / avgTradesPerYear;
  const excessReturnPerTrade = averageTradeReturn - riskFreeRatePerTrade;

  const sharpeRatio = toFixed(
    (excessReturnPerTrade / (deviationTradeReturn / 100)) *
      Math.sqrt(avgTradesPerYear)
  );
  // const annualizedDeviation =
  //   (deviationTradeReturn / 100) * Math.sqrt(avgTradesPerYear);
  // const annualizedPnl = strategy.metrics.yearlyPnl.average / 100;
  // const compoundedSharpe = (annualizedPnl - 0.03) / annualizedDeviation;
  return sharpeRatio;
};

export const startBacktest = (backtestSettings) => {
  const strategy = {
    _id: uuidv4(),
    name: "Untitled",
    symbol: backtestSettings.symbol,
    transactions: [],
    openOrders: [],
    enabled: true,
    created: backtestSettings?.predictionsTable?.[0]?.date || new Date(),

    premiumValue: backtestSettings.userCommission,
    premiumPerShare: backtestSettings.premiumPerShare,

    model: backtestSettings.modelId,

    params: {
      takeProfitPercent: backtestSettings.takeProfitPercent,
      stopLossPercent: backtestSettings.stopLossPercent,
      // trailingStopLossPercent: backtestSettings.trailingStopLossPercent,
      enterPositionBuyCount: backtestSettings.enterPositionBuyCount,
      exitPositionNoBuyCount: backtestSettings.exitPositionNoBuyCount,
      daysOpenExitCount: backtestSettings.daysOpenExitCount,
      orderSizeType: backtestSettings.orderSizeType,
      orderSize: backtestSettings.orderSize,
      maxOpenOrders: backtestSettings.maxOpenOrders || 1,
      resetTrendAfterSell: backtestSettings.resetTrendAfterSell,
      slippage: backtestSettings.slippage,
    },

    initialBalance: backtestSettings.initialBalance,
    balance: backtestSettings.initialBalance,
    equity: backtestSettings.initialBalance,

    predictions: backtestSettings.predictionsTable,
    lastUpdate: null,
    firstClosePrice: null,
    buyPredictionsInRow: 0,
    noBuyPredictionsInRow: 0,

    metrics: {
      totalTransactions: 0,
      totalCommissions: 0,

      closedWithProfit: 0,
      closedWithNetProfit: 0,
      successRate: 0,

      buyHoldReturn: 0,
      pnl: 0,
      pnlPercent: 0,
      grossProfit: 0,
      grossLoss: 0,
      profitFactor: 0,
      averageProfit: 0,
      averageDuration: 0,
      averageWinDuration: 0,
      averageLossDuration: 0,
      highestProfit: 0,
      highestLoss: 0,
      avgLoss: 0,
      avgProfit: 0,
      winningTrades: 0,
      lossingTrades: 0,
      reachedModelTarget: 0,
      strategySuccessRate: 0,
      sharpeRatio: 0,
      sortinoRatio: 0,
      maxEquity: 0,
      maxDrawdown: 0,
    },
  };

  // iterate on all candles, every iteration = 1 candle (trading day)
  strategy.predictions.forEach((currentPredict) => {
    if (
      backtestSettings.startDate > +new Date(currentPredict.date) ||
      backtestSettings.endDate < +new Date(currentPredict.date)
    ) {
      return;
    }
    updateStrategyDataDaily(strategy, currentPredict);

    if (strategy.openOrders.length > 0) {
      strategy.openOrders.forEach((order) => {
        checkShouldExitPosition(order, strategy, currentPredict);
      });
    }

    if (currentPredict?.prediction === "buy") {
      // bot wants to buy based on new prediciton
      const canEnterPosition = checkIfCanEnterPosition(
        strategy,
        currentPredict
      );

      if (canEnterPosition) {
        executeBuyOrder(strategy, currentPredict);
      }
    }
  });

  const { transactions } = strategy;

  const transactionsGrouped = transactions
    .slice()
    .reverse()
    .reduce(
      (acc, transaction) => {
        const date = new Date(transaction.closeDate);
        const month = date.getMonth();
        const year = date.getFullYear();
        const monthKey = `${year}-${month + 1}`;
        // group months
        if (!acc.months[monthKey]) {
          acc.months[monthKey] = [];
        }
        acc.months[monthKey].push(transaction);

        // group years
        if (!acc.years[year]) {
          acc.years[year] = [];
        }
        acc.years[year].push(transaction);
        return acc;
      },
      { months: {}, years: {} }
    );

  let winningMonths = 0;
  let lossingMonths = 0;
  let totalMonths = 0;

  const monthlyPnl = Object.entries(transactionsGrouped.months).map((entry) => {
    const transactions = entry[1];
    const pnl = transactions.reduce((acc, transaction) => {
      return acc + transaction.netProfit.value;
    }, 0);

    const startBalance = transactions[transactions.length - 1].balance - pnl;
    const endBalance = transactions[transactions.length - 1].balance;

    const percent = calcProfitPerc(startBalance, endBalance);

    // set win-loss and ratio
    if (pnl > 0) {
      winningMonths++;
    } else {
      lossingMonths++;
    }

    totalMonths++;

    return {
      label: dayjs(transactions[0].closeDate).format("MM-YYYY"),
      percent: toFixed(percent),
      value: toFixed(pnl),
      win: pnl > 0,
    };
  });

  const yearlyPnl = Object.entries(transactionsGrouped.years).map((entry) => {
    const key = entry[0];
    const transactions = entry[1];
    const pnl = transactions.reduce((acc, transaction) => {
      return acc + transaction.netProfit.value;
    }, 0);

    const startBalance = transactions[transactions.length - 1].balance - pnl;
    const endBalance = transactions[transactions.length - 1].balance;

    const percent = calcProfitPerc(startBalance, endBalance);

    return {
      label: key,
      percent: toFixed(percent),
      value: toFixed(pnl),
      win: pnl > 0,
    };
  });

  const averageMonthlyGainPercent =
    monthlyPnl.reduce((acc, month) => acc + month.percent, 0) /
    monthlyPnl.length;

  const averageAnnualGainPercent =
    yearlyPnl.reduce((acc, year) => acc + year.percent, 0) / yearlyPnl.length;

  strategy.metrics.monthlyPnl = {
    data: monthlyPnl,
    average: toFixed(averageMonthlyGainPercent),
  };
  strategy.metrics.yearlyPnl = {
    data: yearlyPnl,
    average: toFixed(averageAnnualGainPercent),
  };
  strategy.metrics.avgTransactionsPerMonth = toFixed(
    transactions.length / monthlyPnl.length,
    2
  );

  strategy.metrics.avgTransactionsPerYear = toFixed(
    transactions.length / yearlyPnl.length,
    2
  );

  strategy.metrics.winLossRatio = toFixed(winningMonths / lossingMonths);
  strategy.metrics.monthlySuccessRate = toFixed(
    (winningMonths / totalMonths) * 100
  );
  strategy.metrics.winningMonths = winningMonths;
  strategy.metrics.lossingMonths = lossingMonths;
  strategy.metrics.totalMonths = totalMonths;

  strategy.metrics.standardDeviation = calculateStandardDeviation(
    strategy.transactions,
    strategy
  );

  strategy.metrics.upsideDeviation = calculateUpsideDeviation(
    strategy.transactions,
    strategy
  );

  strategy.metrics.downsideDeviation = calculateDownsideDeviation(
    strategy.transactions,
    strategy
  );

  // Calculate 95th and 5th percentiles
  const percentile95 = getPercentile(transactions, 95);
  const percentile5 = Math.abs(getPercentile(transactions, 5));

  // Calculate the Tail Ratio
  const tailRatio = percentile95 / percentile5;

  strategy.metrics.tailRatio = toFixed(tailRatio);
  strategy.metrics.commonSenseRatio = toFixed(
    tailRatio * strategy.metrics.profitFactor
  );

  strategy.metrics.sharpeRatio = calculateSharpeOrSortinoRatio(
    strategy,
    strategy.metrics.standardDeviation
  );

  strategy.metrics.sortinoRatio = calculateSharpeOrSortinoRatio(
    strategy,
    strategy.metrics.downsideDeviation
  );

  return strategy;
};
