import axios from "utils/axios";
import { routes } from "api/routes";

export const apiAnalyzePredictions = async (params) => {
  const { data } = await axios.post(routes.analyzePredictions, params);
  return data;
};

export const apiFetchPredictionsMultipleModels = async (formData) => {
  const { data } = await axios.post(
    routes.getPredictionsMultipleModels,
    formData
  );
  return data;
};

export const apiSearchBacktestStrategies = async (formData) => {
  const { data } = await axios.post(routes.searchBacktestStrategies, formData);
  return data;
};

export const apiGetSymbolRecommendations = async (
  symbol,
  startDate,
  endDate,
  returnDict = false
) => {
  const { data } = await axios.get(routes.getSymbolRecommendations, {
    params: { symbol, startDate, endDate, returnDict },
  });
  return data;
};

export const apiEvaluateBuySellPolarity = async (formData) => {
  const { data } = await axios.post(routes.evaluateBuySellPolarity, formData);
  return data;
};
