import React, { useEffect, useState } from "react";
import { apiGetStrategyById } from "api/apiStrategies";
import { useLocation, useNavigate } from "react-router-dom";

import GenericCard from "components/UI/GenericCard/GenericCard";
import AiBacktestTable from "components/AiMagician/AiBacktestTable";
import AccProfitChart from "components/AiMagician/Charts/AccProfitChart";
import AverageProfitPerTransaction from "components/Cards/AverageProfitPerTransaction";
import TotalPositionsClosedWithProfit from "components/Cards/TotalPositionsClosedWithProfit";
import BuyAndHoldReturn from "components/Cards/BuyAndHoldReturn";
import MinMaxPerTransactionCard from "components/Cards/MinMaxPerTransactionCard";
import TotalTransactions from "components/Cards/TotalTransactions";
import StrategyOpenOrders from "components/Strategies/Cards/StrategyOpenOrders";
import TVChartContainer from "components/TVChartContainer/TVChartContainer";

const StrategyPreview = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const strategyId = queryParams.get("strategyId");

  const [selectedStrategy, setSelectedStrategy] = useState(null);

  const [visibleRange, setVisibleRange] = useState({ from: 0, to: 0 });

  const setTransactionVisibleRange = (openDate, closeDate) => {
    const from = Math.floor(+new Date(openDate)) / 1000;
    const to = Math.floor(+new Date(closeDate)) / 1000;
    setVisibleRange({ from, to });
  };

  const resetVisibleRange = () => {
    setVisibleRange({ from: 0, to: 0 });
  };

  const getStrategyDetails = async () => {
    const response = await apiGetStrategyById(strategyId);
    setSelectedStrategy(response);
    document.title = `${response.name} - Dindactor`;
  };

  useEffect(() => {
    if (strategyId) {
      getStrategyDetails();
    } else {
      navigate("/");
    }
  }, [strategyId]);

  if (!selectedStrategy) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1 className="pb-2">{selectedStrategy.name}</h1>
      <h3 className="pb-2 text-gray-light">
        Symbol: {selectedStrategy.symbol}
      </h3>
      <h3 className="pb-6 text-gray-light">
        Model: {selectedStrategy.model.name}
      </h3>

      <div className="cards-wrapper">
        <TotalTransactions backtestResults={selectedStrategy} />
        <BuyAndHoldReturn backtestResults={selectedStrategy} />
        <TotalPositionsClosedWithProfit backtestResults={selectedStrategy} />
        <MinMaxPerTransactionCard backtestResults={selectedStrategy} />
        <AverageProfitPerTransaction backtestResults={selectedStrategy} />

        {selectedStrategy.openPositions.length > 0 && (
          <GenericCard
            title="Open Orders"
            className="table-card"
            maxHeight={610}
          >
            <StrategyOpenOrders
              openOrders={selectedStrategy.openPositions}
              profitTargetPercent={selectedStrategy.params.takeProfitPercent}
            />
          </GenericCard>
        )}

        {selectedStrategy.transactions.length > 0 && (
          <AiBacktestTable
            transactions={selectedStrategy.transactions}
            setTransactionVisibleRange={setTransactionVisibleRange}
          />
        )}
        <GenericCard
          title="Trading View"
          className="table-card"
          isCardOpenByDefault={false}
        >
          <TVChartContainer
            symbol={selectedStrategy.symbol}
            transactions={selectedStrategy.transactions}
            openPositions={selectedStrategy.openPositions}
            visibleRange={visibleRange}
            resetVisibleRange={resetVisibleRange}
          />
        </GenericCard>
        <AccProfitChart strategy={selectedStrategy} />

        {/* {predictions && <PredictionsTable data={predictions} isOpen={false} />} */}

        {/* <StrategyDetails
        strategy={selectedStrategy}
        getUserStrategies={getUserStrategies}
        strategyTabs={strategyTabs}
      /> */}

        {/* <StrategyActivityCard selectedStrategy={selectedStrategy} /> */}

        {/* <StrategyNotes
        strategy={selectedStrategy}
        getUserStrategies={getUserStrategies}
      /> */}
      </div>
      <h3 className="text-gray-light">
        Created by: {selectedStrategy.creatorName}
      </h3>
    </div>
  );
};

export default StrategyPreview;
