import React, { useState, useEffect, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { paths } from "utils/constants";
import { apiGetModelsList } from "api/apiAiModels";
import {
  apiAnalyzePredictions,
  apiFetchPredictionsMultipleModels,
} from "api/apiPredictions";
import dayjs from "dayjs";
import dayjsBusinessDays from "dayjs-business-days";

import { Button, Form, Input, Modal, Segmented, Tabs } from "antd";

import ConsultAiForm from "./ConsultAiForm";
import ChooseModel from "./ChooseModel";
import AllResults from "./AllResults";
import BacktestStrategy from "./BacktestResults";
import ResultsHistory from "./ResultsHistory";
import StrategiesExplorer from "components/StrategiesExplorer/StrategiesExplorer";

import "./AiMagician.scss";

dayjs.extend(dayjsBusinessDays);

const AiMagician = () => {
  const [form] = Form.useForm();
  const [backtestResults, setBacktestResults] = useState([]);
  const [selectedResultTab, setSelectedResultTab] = useState("");
  const [modelsList, setModelsList] = useState([]);
  const [resultsHistory, setResultsHistory] = useState([]);
  const [shownPredictionResults, setShownPredictionResults] = useState([]);
  const [isStrategyExplorer, setIsStrategyExplorer] = useState(false);
  const [isAddBacktestUrlOpen, setIsAddBacktestUrlOpen] = useState(false);
  const [backtestUrl, setBacktestUrl] = useState("");

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isShared = queryParams.get("shared");

  const [strategyResults, setStrategyResults] = useState({});

  const selectedIds = useMemo(
    () =>
      backtestResults.reduce((acc, item) => {
        acc[item._id] = 1;
        return acc;
      }, {}),
    [backtestResults]
  );

  const getModelsList = async () => {
    const response = await apiGetModelsList();
    setModelsList(response);
  };

  const onLoadAiResult = async (
    result,
    backtestParams,
    params = queryParams
  ) => {
    let models = [result.model._id];
    if (params.get("modelId")) {
      const modelsFromUrl = params.get("modelId").split(",");
      models = modelsFromUrl.map((item) => item.trim());
    }

    const predictions = await apiFetchPredictionsMultipleModels({
      symbol: result.symbol,
      models: models,
      from: result.startDate,
      to: result.endDate,
    });

    const backtestResult = {
      ...result,
      predictionsTable: predictions,
      backtestParams,
      selectedModels: models,
    };

    setBacktestResults((prevState) => {
      const isLoaded = prevState.find((item) => item._id === result._id);
      if (isLoaded) {
        return prevState;
      }
      return [backtestResult, ...prevState];
    });
    setSelectedResultTab(result._id);
  };

  const onRemoveAiResult = (resultId) => {
    setBacktestResults((prevState) =>
      prevState.filter((item) => item._id !== resultId)
    );
    if (resultId === selectedResultTab) {
      setSelectedResultTab(backtestResults[0]?.id);
    }
  };

  const clearBacktestResults = () => {
    setBacktestResults([]);
  };

  const handleChangeTab = (resultId) => {
    setSelectedResultTab(resultId);
  };

  const handleSelectModel = async (_results, selectedModels) => {
    if (!selectedModels.includes(_results.model._id)) {
      selectedModels = [_results.model._id, ...selectedModels];
    }

    const predictions = await apiFetchPredictionsMultipleModels({
      symbol: _results.symbol,
      models: [...selectedModels],
      from: _results.startDate,
      to: _results.endDate,
    });

    setBacktestResults((prevState) => {
      const foundResultIndex = backtestResults.findIndex(
        (item) => item._id === _results._id
      );
      // Create a copy of the prevState to avoid direct mutation
      const newState = [...prevState];

      newState[foundResultIndex] = {
        ...newState[foundResultIndex],
        predictionsTable: predictions,
        selectedModels,
      };
      return newState;
    });
  };

  const prepareFormData = (models) => {
    const values = form.getFieldsValue();
    const modelIds = models.map((item) => item._id);
    const modelObj = models[0];

    // sync with model
    let startDate = dayjs(modelObj.results.end_date);
    let endDate = dayjs();

    if (values.dateType === "custom") {
      startDate = values.dates[0];
      endDate = values.dates[1];
    } else if (values.dateType === "synced_min") {
      const isModelDateBeforeMinDate = dayjs(
        modelObj.results.end_date
      ).isBefore(dayjs(values.minDate));

      if (isModelDateBeforeMinDate) {
        startDate = dayjs(values.minDate);
      }
    }

    return {
      symbols: values.symbols,
      models: modelIds,
      startDate: startDate.format("YYYY-MM-DD"),
      endDate: endDate.add(1, "d").format("YYYY-MM-DD"),
    };
  };

  const handleLoadSharedBacktestFromUrl = async (url) => {
    const params = url ? new URLSearchParams(url) : queryParams;

    const query = {
      symbols: [params.get("symbol")],
      models: [params.get("modelId").split(",")[0].trim()],
      startDate: params.get("startDate"),
      endDate: dayjs(params.get("endDate")).add(1, "d").format("YYYY-MM-DD"),
    };
    let results = await apiAnalyzePredictions(query);
    results = results.filter((item) => item.lastClosePrice);

    setResultsHistory((prevState) => {
      const isAlreadyInHistory = prevState.some(
        (item) => item._id === results[0]._id
      );
      if (isAlreadyInHistory) {
        return prevState;
      }
      return [...results, ...prevState];
    });
    onLoadAiResult(results[0], params);
  };

  const handleCheckAiSymbol = async (model) => {
    await form.validateFields();
    let selectedModelsList = [model];
    if (!model) {
      selectedModelsList = modelsList;
    }

    const task = prepareFormData(selectedModelsList);
    let results = await apiAnalyzePredictions(task);
    // results = results.filter((item) => item.lastClosePrice);

    setResultsHistory((prevState) => [...results, ...prevState]);

    form.setFieldValue("symbol", []);
  };

  const handleAddBacktestFromUrl = () => {
    handleLoadSharedBacktestFromUrl(backtestUrl);
    setBacktestUrl("");
    setIsAddBacktestUrlOpen(false);
  };

  useEffect(() => {
    if (isShared) {
      handleLoadSharedBacktestFromUrl();
    }
  }, [isShared]);

  useEffect(() => {
    document.title = "AI Backtests | Dindicator Dashboard";
  }, []);

  const items = useMemo(() => {
    return backtestResults.map((item) => {
      return {
        key: item._id,
        label: `${item.symbol} - %${item.tp.toFixed(2)} - ${item.model.name}`,
        children: (
          <BacktestStrategy
            results={item}
            setStrategyResults={setStrategyResults}
            modelsList={modelsList}
            handleSelectModel={handleSelectModel}
          />
        ),
      };
    });
  }, [backtestResults]);

  const onEdit = (targetKey, action) => {
    if (action === "add") {
      setIsAddBacktestUrlOpen(true);
    } else {
      onRemoveAiResult(targetKey);
    }
  };

  const onSelectAllTab = () => {
    setSelectedResultTab("all");
  };

  useEffect(() => {
    getModelsList();
  }, []);

  return (
    <div className="ai-magician-wrapper">
      {isShared ? (
        <Link to={paths.AiMagician}>
          <Button type="primary" className="mb-4">
            New Search
          </Button>
        </Link>
      ) : (
        <div className="cards-wrapper top">
          <ConsultAiForm form={form} />
          <ChooseModel
            modelsList={modelsList}
            getModelsList={getModelsList}
            handleCheckAiSymbol={handleCheckAiSymbol}
          />
        </div>
      )}
      <ResultsHistory
        history={resultsHistory || []}
        onLoadAiResult={onLoadAiResult}
        setResultsHistory={setResultsHistory}
        modelsList={modelsList}
        onRemoveAiResult={onRemoveAiResult}
        selectedIds={selectedIds}
        setShownPredictionResults={setShownPredictionResults}
        shownPredictionResults={shownPredictionResults}
        isShared={isShared}
      />

      {!isShared && resultsHistory && resultsHistory.length > 0 && (
        <div style={{ paddingBlockEnd: 10 }}>
          <Segmented
            options={[
              { label: "Backtests", value: false },
              { label: "Strategies Explorer", value: true },
            ]}
            value={isStrategyExplorer}
            onChange={setIsStrategyExplorer}
          />
        </div>
      )}

      {isStrategyExplorer && (
        <StrategiesExplorer
          onRemoveAiResult={onRemoveAiResult}
          selectedIds={selectedIds}
          shownPredictionResults={shownPredictionResults}
          onLoadAiResult={onLoadAiResult}
        />
      )}

      {backtestResults.length > 0 && (
        <div style={{ minHeight: 800 }}>
          <Tabs
            type="editable-card"
            items={items}
            onEdit={onEdit}
            tabBarExtraContent={{
              left: (
                <Button
                  type="text"
                  style={{
                    // height: 35,
                    marginInlineEnd: 5,
                    color: selectedResultTab === "all" ? "#1668dc" : "",
                  }}
                  onClick={onSelectAllTab}
                >
                  Summary
                </Button>
              ),
              right: (
                <div>
                  <Button type="primary" onClick={clearBacktestResults}>
                    Clear
                  </Button>
                </div>
              ),
            }}
            activeKey={selectedResultTab}
            onChange={handleChangeTab}
          />
          <Modal
            title="Insert backtest url to load"
            open={isAddBacktestUrlOpen}
            centered
            onOk={handleAddBacktestFromUrl}
            onCancel={() => setIsAddBacktestUrlOpen(false)}
          >
            <Input
              placeholder="Insert backtest url"
              onChange={(e) => setBacktestUrl(e.target.value)}
              value={backtestUrl}
            />
          </Modal>
          {selectedResultTab === "all" && strategyResults && (
            <AllResults strategyResults={strategyResults} />
          )}
        </div>
      )}
    </div>
  );
};

export default AiMagician;
