import React, { useCallback } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";

import GenericCard from "components/UI/GenericCard/GenericCard";
import AntTable from "components/UI/AntTable";
import { Button, Tooltip } from "antd";

import {
  createURLWithQueryParams,
  returnUpDownClassname,
  toFixed,
} from "utils/helpers";
import ProfileAvatar from "components/UI/ProfileAvatar";
import FirstPrizePng from "assets/1st-prize.png";
import SecondPrizePng from "assets/2nd-place.png";
import ThirdPrizePng from "assets/3rd-place.png";
import { paths } from "utils/constants";

const LeaderboardsTable = (props) => {
  const { strategies } = props;

  const createShareUrl = (strategy) => {
    const params = {
      ...strategy.params,
      shared: true,
      symbol: strategy.symbol,
      modelId: strategy.models.map((model) => model._id).join(", "),
      initialBalance: 10000,
      userCommission: 0,
      startDate: dayjs(strategy.created).format("YYYY-MM-DD"),
      endDate: dayjs().format("YYYY-MM-DD"),
    };
    console.log(
      createURLWithQueryParams(`${window.location.origin}${paths.ai}/`, params)
    );

    return createURLWithQueryParams(
      `${window.location.origin}${paths.ai}/`,
      params
    );
  };

  const sortByNumber = useCallback((a, b) => {
    // treat null as 0
    const valA = a === null || a === "N/A" ? 0 : a;
    const valB = b === null || b === "N/A" ? 0 : b;
    if (valA === valB) return 0;
    return valA > valB ? 1 : -1;
  }, []);

  const sortByText = useCallback((a, b) => {
    if (a === null || a === "N/A") return 1;
    if (b === null || b === "N/A") return -1;
    return a.toLowerCase().localeCompare(b.toLowerCase());
  }, []);

  const columns = [
    {
      title: "Rank",
      dataIndex: "rank",
      render: (text, properties) => (
        <div className="flex items-center">
          <div className="min-w-[35px] text-lg text-center justify-center items-center flex me-5">
            {text === 1 ? (
              <img src={FirstPrizePng} alt="1st" />
            ) : text === 2 ? (
              <img src={SecondPrizePng} alt="2nd" />
            ) : text === 3 ? (
              <img src={ThirdPrizePng} alt="3rd" />
            ) : (
              <div>{text}</div>
            )}
          </div>
          <ProfileAvatar user={properties.owner} />
        </div>
      ),
    },
    {
      title: "Backtest",
      key: "uid",
      dataIndex: "",
      render: (text, propeties) => (
        <Button href={createShareUrl(propeties)} target="_blank">
          Load
        </Button>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: {
        compare: (a, b) => sortByText(a.name, b.name),
      },
      render: (text) => (
        <div className="truncate max-w-[200px]">
          <Tooltip title={text}>{text}</Tooltip>
        </div>
      ),
    },
    {
      title: "Symbol",
      dataIndex: "symbol",
      render: (text) => <div className="min-w-[50px]">{text}</div>,
      sorter: {
        compare: (a, b) => sortByText(a.symbol, b.symbol),
      },
    },
    {
      title: "Models",
      dataIndex: "models",
      render: (text) => {
        const renderText = text.map((model, index) => (
          <div key={index}>
            <strong>{index + 1}.</strong> {model.name}
          </div>
        ));
        return (
          <div className="max-w-[200px] truncate">
            <Tooltip title={renderText}>{renderText}</Tooltip>
          </div>
        );
      },
    },
    {
      title: "PNL",
      dataIndex: ["metrics", "pnlPercent"],
      render: (text) => (
        <div className={returnUpDownClassname(text)}>
          <div>{text.toLocaleString()}%</div>
        </div>
      ),
      sorter: {
        compare: (a, b) =>
          sortByNumber(a.metrics.pnlPercent, b.metrics.pnlPercent),
      },
    },
    {
      title: "TWR",
      dataIndex: ["metrics", "twr"],
      render: (text) => (
        <div className={returnUpDownClassname(text)}>
          <div>{text.toLocaleString()}%</div>
        </div>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.metrics.twr, b.metrics.twr),
      },
    },
    {
      title: "Average PNL",
      dataIndex: ["metrics", "averageProfit"],
      render: (text) => <span>{text || (0).toFixed(2)}%</span>,
      sorter: {
        compare: (a, b) =>
          sortByNumber(a.metrics.averageProfit, b.metrics.averageProfit),
      },
    },
    {
      title: "Success",
      dataIndex: ["metrics", "successRate"],
      render: (text, properties) => (
        <div className="min-w-[60px]">
          <div>{text}%</div>
          <div>
            {properties.metrics.closedWithProfit} /{" "}
            {properties.metrics.totalTransactions}
          </div>
        </div>
      ),
      sorter: {
        compare: (a, b) =>
          sortByNumber(a.metrics.successRate, b.metrics.successRate),
      },
    },
    {
      title: "TP",
      dataIndex: "params.takeProfitPercent",
      render: (text, properties) => (
        <span>{properties.params.takeProfitPercent}%</span>
      ),
      sorter: {
        compare: (a, b) =>
          sortByNumber(a.params.takeProfitPercent, b.params.takeProfitPercent),
      },
    },
    {
      title: "SL",
      dataIndex: "params.stopLossPercent",
      render: (text, properties) => (
        <span>{properties.params.stopLossPercent}%</span>
      ),
      sorter: {
        compare: (a, b) =>
          sortByNumber(a.params.stopLossPercent, b.params.stopLossPercent),
      },
    },
    {
      title: "Days Exit",
      dataIndex: ["params", "daysOpenExitCount"],
      render: (text) => <div className="min-w-[50px]">{text}</div>,
      sorter: {
        compare: (a, b) =>
          sortByNumber(a.params.daysOpenExitCount, b.params.daysOpenExitCount),
      },
    },
    {
      title: "Average hold",
      dataIndex: "metrics.averageDuration",
      render: (text, properties) => (
        <span>{toFixed(properties.metrics.averageDuration || 0, 1)} days</span>
      ),
      sorter: {
        compare: (a, b) =>
          sortByNumber(a.metrics.averageDuration, b.metrics.averageDuration),
      },
    },
    {
      title: "Mode",
      dataIndex: "isDemo",
      render: (text) => (
        <div
          className={`
          ${text ? "text-[#ff2a2a]" : ""}
          `}
        >
          {text ? "Demo" : "Real"}
        </div>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.isDemo, b.isDemo),
      },
    },
    {
      title: "Start date",
      dataIndex: "created",
      render: (text) => <>{dayjs(text).format("DD/MM/YY")}</>,
      sorter: {
        compare: (a, b) => sortByNumber(a.created, b.created),
      },
    },
  ];

  return (
    <div className="cards-wrapper">
      <GenericCard
        title={`Top ${strategies.length} Performers`}
        className="table-card"
        maxHeight={900}
      >
        <AntTable
          rowKey="_id"
          dataSource={strategies}
          columns={columns}
          virtual={false}
        />
      </GenericCard>
    </div>
  );
};

LeaderboardsTable.propTypes = {
  strategies: PropTypes.array,
  onLoadStrategy: PropTypes.func,
  onUnloadStrategy: PropTypes.func,
  selectedIds: PropTypes.object,
};

export default LeaderboardsTable;
