import React from "react";
import PropTypes from "prop-types";

import {
  Row,
  Form,
  Button,
  Col,
  DatePicker,
  Select,
  Divider,
  Space,
  Segmented,
  Checkbox,
  InputNumber,
} from "antd";
import dayjs from "dayjs";

import GenericCard from "components/UI/GenericCard/GenericCard";
import { modelSearchRangePresets } from "utils/constants";

import { useSelector } from "react-redux";

const { RangePicker } = DatePicker;
const dateFormat = "DD/MM/YY";

const ConsultAiForm = (props) => {
  const { form } = props;
  const dateType = Form.useWatch("dateType", form);
  const whatToShow = Form.useWatch("whatToShow", form);

  const symbolsGroupedInSectored = useSelector(
    (state) => state.main.symbolsGroupedInSectored
  );

  const symbolsList = useSelector((state) => state.main.symbolsList);

  const handleSelectAll = (type) => {
    let symbols = [];
    switch (type) {
      case "tech":
        symbols = symbolsList.reduce((acc, item) => {
          if (item.sector.code === "technology") acc.push(item.ticker);
          return acc;
        }, []);
        break;

      default:
        symbols = symbolsList.map((symbol) => symbol.ticker);
        break;
    }

    form.setFieldValue("symbols", symbols);
  };

  return (
    <GenericCard title="Predict with AI">
      <Form
        form={form}
        layout="vertical"
        name="ai"
        initialValues={{
          dateType: "custom",
          dates: [dayjs().add(-2, "y"), dayjs()],
          minDate: dayjs().add(-2, "y"),
        }}
        style={{
          maxWidth: 400,
        }}
      >
        <Form.Item name="dateType" label="Dates">
          <Segmented
            options={[
              { label: "Custom dates", value: "custom" },
              { label: "Synced with model", value: "synced" },
              // { label: "Synced & min date", value: "synced_min" },
              // { label: "Multiple Years", value: "multiple_years" },
            ]}
          />
        </Form.Item>
        <Row gutter={5} align="bottom">
          {/* {dateType === "synced_min" && (
            <Col xs={24} sm={24}>
              <Form.Item name="minDate">
                <DatePicker
                  format={dateFormat}
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
          )} */}
          {dateType === "custom" && (
            <Col xs={24} sm={24}>
              <Form.Item name="dates">
                <RangePicker
                  presets={modelSearchRangePresets}
                  format={dateFormat}
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
          )}
          {/* 
          {dateType === "multiple_years" && (
            <>
              <Col xs={24} sm={24}>
                <Form.Item
                  name="yearsToCheck"
                  label="Years to check"
                  initialValue={5}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24}>
                <Form.Item label="" name="whatToShow" initialValue="each_year">
                  <Segmented
                    options={[
                      { label: "Each Year", value: "each_year" },
                      { label: "Till Today", value: "till_today" },
                    ]}
                  />
                </Form.Item>
              </Col>
              {whatToShow === "each_year" && (
                <Col>
                  <Form.Item name="show_summary" valuePropName="checked">
                    <Checkbox>Show max range result</Checkbox>
                  </Form.Item>
                </Col>
              )}
            </>
          )} */}

          <Col xs={24} sm={24}>
            <Form.Item
              name="symbols"
              label="Symbol"
              // normalize={(value) => (value || "").toUpperCase()}
              required={false}
              rules={[
                {
                  required: true,
                  message: "Select at least one symbol",
                },
              ]}
            >
              <Select
                placeholder="Select symbols"
                mode="multiple"
                options={symbolsGroupedInSectored}
                style={{
                  width: "100%",
                }}
                filterOption={(inputValue, option) =>
                  option.label
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
                optionLabelProp="value"
                maxTagCount="responsive"
                allowClear
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider
                      orientation="left"
                      style={{
                        margin: "8px 0",
                        fontSize: "14px",
                      }}
                    >
                      Select groups
                    </Divider>
                    <Space
                      style={{
                        padding: "0 8px 4px",
                      }}
                    >
                      <Button
                        type="text"
                        // icon={<PlusOutlined />}
                        onClick={() => handleSelectAll()}
                      >
                        All
                      </Button>
                      <Button
                        type="text"
                        // icon={<PlusOutlined />}
                        onClick={() => handleSelectAll("tech")}
                      >
                        Tech
                      </Button>
                    </Space>
                  </>
                )}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </GenericCard>
  );
};

ConsultAiForm.propTypes = {
  form: PropTypes.any,
};

export default ConsultAiForm;
